import React from 'react'
import Logo from './Common/Images/Logo'
import {NavLink} from 'react-router-dom'
import {getSsoUrl} from '../../../services/user-service'
import {useRecoilValue} from 'recoil'
import {dashboardRestrictionState} from '../../../state/pulse'
import {dashboardRestriction} from '../../../types/pulse'

const DashboardFooter = () => {
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)

	return (
		<footer className="
			flex flex-1 items-end max-w-6xl mb-20 px-4 w-full
			bg-white
			lg:mx-auto lg:px-10 lg:mb-0"
		>
			<div className="
				py-2 w-full
				border-gray-200 border-t
				lg:py-5"
			>
				<div className="flex items-center justify-between">
					<div className="flex flex-shrink-0 items-center mr-6">
						<Logo className="opacity-20"/>
					</div>
					{dashboardRestrictions !== dashboardRestriction.External && (
						<div className="flex-1">
							<NavLink
								className="
									mr-2 pr-2
									text-pulse-label-gray
									border-r border-pulse-label-gray
									hover:text-neutral-400"
								to="/"
							>
								Legacy Dashboard
							</NavLink>
							<NavLink
								className="
									text-pulse-label-gray
									hover:text-neutral-400"
								to={getSsoUrl() ?? '#'}
							>
								SSO Dashboard
							</NavLink>
						</div>
					)}
				</div>
			</div>
		</footer>
	)
}

export default DashboardFooter
