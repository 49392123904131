import React from 'react'
import {useRecoilValue} from 'recoil'
import {selectedDateRangeState} from '../../../../../state/pulse-filters'
import {dateRange, PulseTrendTag} from '../../../../../types/pulse'

const TrendCardText = (props: { trendText?: string, trendValue: number }) => {
	const date = useRecoilValue(selectedDateRangeState)
	const period: Record<dateRange, string> = {
		[dateRange.YearToDate]: 'year',
		[dateRange.Year]: 'year',
		[dateRange.Month]: 'month',
		[dateRange.Custom]: '',
		[dateRange.AllTime]: ''
	}

	const trendText = (props.trendText
			? props.trendText
			: `${PulseTrendTag.value}% vs last ${PulseTrendTag.period}`)
		.replace(PulseTrendTag.period, period[date])

	return (
		<>
			{props.trendValue > 0 && (
				<div className="text-xs text-pulse-blue">
					<span className="
						align-middle mb-1
						text-xs
						material-symbols-outlined"
					>
						keyboard_arrow_up
					</span>
					<span className="font-medium">
						{trendText.replace(PulseTrendTag.value, props.trendValue.toString())}
					</span>
				</div>
			)}
			{props.trendValue < 0 && (
				<div className="text-xs text-pulse-red">
					<span className="
						mb-1 align-middle
						text-xs
						material-symbols-outlined"
					>
						keyboard_arrow_down
					</span>
					<span className="font-medium">
						{trendText.replace(PulseTrendTag.value, (props.trendValue * -1).toString())}
					</span>
				</div>
			)}
		</>
	)
}

export default TrendCardText