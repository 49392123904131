import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {
	verticalsPageFilterState,
	verticalsPageOverviewChartFilterState,
	verticalsPageVerticalState,
	verticalsRevenueTableFilterState
} from '../../../state/pulse'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'
import {CardColumn, CardHeader, TableColumn, TableHeader} from './Common/TableElements'
import {Currency, Percentage} from './Common/FormatElements'
import VerticalsSortColumnDropdown from './Common/Verticals/VerticalsSortColumnDropdown'
import H2 from './Common/Headers/H2'
import {Bar, Pie} from 'react-chartjs-2'
import VerticalsBreakdownColumnDropdown from './Common/Verticals/VerticalsBreakdownColumnDropdown'
import {ViewGrid} from './Common/LayoutElements'
import OverviewConversionRateSummary from './OverviewConversionRateSummary'
import OverviewPriceToCustomerSummary from './OverviewPriceToCustomerSummary'
import OverviewRevenuePerTransactionSummary from './OverviewRevenuePerTransactionSummary'
import OverviewRevenuePerCurrencySummary from './OverviewRevenuePerCurrencySummary'
import OverviewGraphChart from './OverviewGraphChart'
import VerticalsVerticalDropdown from './Common/Verticals/VerticalsVerticalDropdown'
import {useResetRecoilState} from 'recoil'
import useGetVerticalsRevenuePieChartData from '../hooks/useGetVerticalsRevenuePieChartData'
import useGetVerticalsRevenueBarChartData from '../hooks/useGetVerticalsRevenueBarChartData'

const VerticalsView = () => {
	const {getVerticalsRevenueTableData} = usePulseApi()
	const tableValue = useApiValue(verticalsRevenueTableFilterState, getVerticalsRevenueTableData, {
		verticals: []
	})
	const pieChartData = useGetVerticalsRevenuePieChartData(tableValue)
	const barChartData = useGetVerticalsRevenueBarChartData(tableValue)
	const resetVertical = useResetRecoilState(verticalsPageVerticalState)
	resetVertical()

	return (
		<>
			<LoadingSpinner
				className="flex items-center justify-center min-h-60 w-full"
				isLoading={tableValue.isLoading}
			>
				<>
					<div className="flex my-4 space-x-4">
						<VerticalsSortColumnDropdown/>
					</div>
					<div className="
						w-full
						text-sm
						lg:hidden"
					>
						{tableValue.data.verticals && tableValue.data.verticals
							.map((row, index) => (
								<div
									className="flex items-center mb-4 space-x-4"
									key={index}
								>
									<div className="
										gap-3 grid grid-cols-[175px_auto] pb-4 w-full
										border-b border-gray-200"
									>
										<CardHeader
											title="Verticals"
										/>
										<CardColumn
											value={<span className="align-middle mr-3">{row.verticalName}</span>}
										/>
										<CardHeader
											className="text-pulse-blue"
											title="PG Revenue"
										/>
										<CardColumn
											className="text-pulse-blue"
											value={<Currency value={row.protectionValue}/>}
										/>
										<CardHeader
											title="RP Sales"
										/>
										<CardColumn
											value={<Currency value={row.refundProtectValue}/>}
										/>
										<CardHeader
											title="Partner's Revenue"
										/>
										<CardColumn
											value={<Currency value={row.profitValue}/>}
										/>
										<CardHeader
											className="text-pulse-red"
											title="Paid Refunds"
										/>
										<CardColumn
											className="text-pulse-red"
											value={<Currency value={row.approvedRefundsValue}/>}
										/>
										<CardHeader
											className="text-pulse-orange"
											title="Loss Ratio"
										/>
										<CardColumn
											className="text-pulse-orange"
											value={<Percentage value={row.lossRatio}/>}
										/>
									</div>
								</div>
						))}
					</div>
					<table className="
						hidden mb-4 table-fixed w-full
						text-sm
						lg:table"
					>
						<thead>
						<tr className="
							mb-2
							border-b border-gray-200"
						>
							<TableHeader title="Verticals"/>
							<TableHeader className="text-pulse-blue w-[14%]" title="PG Revenue"/>
							<TableHeader className="w-[14%]" title="RP Sales"/>
							<TableHeader className="w-[14%]" title="Partner's Revenue"/>
							<TableHeader className="text-pulse-red w-[14%]" title="Paid Refunds"/>
							<TableHeader className="text-pulse-orange w-[14%]" title="Loss Ratio"/>
						</tr>
						</thead>
						<tbody>
						{tableValue.data.verticals && tableValue.data.verticals
							.map((row, index) => (
								<tr
									className="border-b border-gray-200"
									key={index}
								>
									<TableColumn
										value={<span className="align-middle mr-3">{row.verticalName}</span>}
									/>
									<TableColumn
										className="text-pulse-blue"
										value={<Currency value={row.protectionValue}/>}
									/>
									<TableColumn
										value={<Currency value={row.refundProtectValue}/>}
									/>
									<TableColumn
										value={<Currency value={row.profitValue}/>}
									/>
									<TableColumn
										className="text-pulse-red"
										value={<Currency value={row.approvedRefundsValue}/>}
									/>
									<TableColumn
										className="text-pulse-orange"
										value={<Percentage value={row.lossRatio}/>}
									/>
								</tr>
						))}
						</tbody>
					</table>
					{tableValue.data && tableValue.data.verticals && (
						<>
							<H2>Vertical Revenue</H2>
							<div className="flex my-4 space-x-4">
								<VerticalsBreakdownColumnDropdown/>
							</div>
							<div className="
								items-center justify-center
								lg:flex"
							>
								<Pie
									className="
										max-h-96 mb-4
										lg:max-w-md"
									data={{
										datasets: pieChartData.getDatasets(),
										labels: pieChartData.getLabels()
									}}
									options={pieChartData.getOptions()}
								/>
								<Bar
									className="
										max-h-96 mb-4
										lg:max-w-md"
									data={{
										datasets: barChartData.getDatasets(),
										labels: barChartData.getLabels()
									}}
									options={barChartData.getOptions()}
								/>
							</div>
							<H2>Vertical Trends</H2>
							<div className="flex my-4 space-x-4">
								<VerticalsVerticalDropdown/>
							</div>
							<ViewGrid>
								<OverviewConversionRateSummary filterState={verticalsPageFilterState}/>
								<OverviewPriceToCustomerSummary filterState={verticalsPageFilterState}/>
								<OverviewRevenuePerTransactionSummary filterState={verticalsPageFilterState}/>
								<OverviewRevenuePerCurrencySummary filterState={verticalsPageFilterState}/>
							</ViewGrid>
							<div className="my-4">
								<OverviewGraphChart filterState={verticalsPageOverviewChartFilterState}/>
							</div>
						</>
					)}
				</>
			</LoadingSpinner>
		</>
	)
}

export default VerticalsView
