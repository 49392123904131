import React from 'react'
import {
	verticalsRevenueTableBreakdownColumn,
	verticalsRevenueTableBreakdownColumnName
} from '../../../../../types/pulse'
import {useRecoilState} from 'recoil'
import {
	verticalsRevenueTableBreakdownColumnState
} from '../../../../../state/pulse'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'

const VerticalsBreakdownColumnDropdown = () => {
	const [breakdownColumn, setBreakdownColumn] =
		useRecoilState(verticalsRevenueTableBreakdownColumnState)

	const handleClick = (value: verticalsRevenueTableBreakdownColumn) => {
		setBreakdownColumn(value)
	}

	return (
		<Listbox value={breakdownColumn} onChange={(value) => handleClick(value)}>
			<ListboxButton>
				<div className="
					mb-2 pl-3 pr-2 py-[2px]
					bg-pulse-button-blue
					text-nowrap text-sm uppercase
					border border-pulse-icon-gray rounded-full
					cursor-pointer
					lg:mb-0"
				>
					<span className="leading-5 tracking-[1px]">{verticalsRevenueTableBreakdownColumnName[breakdownColumn]}</span>
					<span className="
						align-middle ml-1
						leading-5
						material-symbols-outlined"
					>
						keyboard_arrow_down
					</span>
				</div>
			</ListboxButton>
			<ListboxOptions
				className="
					min-w-[var(--button-width)] ml-4 mt-[5px] py-2 w-11/12 z-50
					bg-white
					border border-pulse-popup-border-gray rounded-lg
					lg:w-auto lg:ml-0"
				anchor="bottom start"
			>
				<DropdownOption breakdownColumn={verticalsRevenueTableBreakdownColumn.ProtectionValue}/>
				<DropdownOption breakdownColumn={verticalsRevenueTableBreakdownColumn.RefundProtectValue}/>
				<DropdownOption breakdownColumn={verticalsRevenueTableBreakdownColumn.ProfitValue}/>
				<DropdownOption breakdownColumn={verticalsRevenueTableBreakdownColumn.ApprovedRefundsValue}/>
				<DropdownOption breakdownColumn={verticalsRevenueTableBreakdownColumn.LossRatio}/>
			</ListboxOptions>
		</Listbox>
	)
}

const DropdownOption = (props: {breakdownColumn: verticalsRevenueTableBreakdownColumn}) => {
	return (
		<ListboxOption
			value={props.breakdownColumn}
			className="
				px-4 py-2
				text-sm text-black tracking-[1px] uppercase
				cursor-pointer
				data-[focus]:bg-pulse-button-blue"
		>
			{verticalsRevenueTableBreakdownColumnName[props.breakdownColumn]}
		</ListboxOption>
	)
}

export default VerticalsBreakdownColumnDropdown
