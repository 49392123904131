export interface PulseFilter extends Record<string, any> {
	coreCurrencyCode: string | null
	dateFrom: string | null
	dateRange: string | null
	dateTo: string | null
	graphFilters: graphFilterType[] | null
	memberId: number | null
	productId: number | null
	regionId: number | null
	userName: string | undefined
	verticalId: number | null
}

export enum dateRange {
	Month,
	Year,
	YearToDate,
	Custom,
	AllTime
}

export interface PulseTableFilter extends PulseFilter {
	page: number
	pageSize: number
	sortColumn: string
	sortDirection: string
	isInsured: boolean
}

export interface PulseTransactionFilter extends PulseUserFilter {
	transactionIds: number[]
}

export interface PulseUserFilter extends Record<string, any> {
	userName: string | undefined
}

export enum graphFilterType {
	GrossBookingValue,
	RefundProtectValue,
	YourRevenue,
	AverageConversionRate,
	GrossBookingVolume,
	RefundProtectVolume,
	RevenuePerDollar,
	ProtectGroupProfit,
	ApprovedRefunds,
	DeclinedRefunds,
	GrossRefunds,
	ApprovedRefundsVolume,
	DeclinedRefundsVolume,
	GrossRefundsVolume
}

export const graphFilterName: Record<graphFilterType, string> = {
	[graphFilterType.RefundProtectValue]: 'Refund Protect Sales',
	[graphFilterType.YourRevenue]: 'Your Revenue',
	[graphFilterType.GrossBookingValue]: 'Gross Booking Sales',
	[graphFilterType.RefundProtectVolume]: 'Refund Protect Volume',
	[graphFilterType.GrossBookingVolume]: 'Gross Booking Volume',
	[graphFilterType.RevenuePerDollar]: 'Gross Revenue Per $',
	[graphFilterType.AverageConversionRate]: 'Conversion Rate',
	[graphFilterType.ProtectGroupProfit]: 'PG Gross Margin',
	[graphFilterType.ApprovedRefunds]: 'Approved Refunds',
	[graphFilterType.DeclinedRefunds]: 'Declined Refunds',
	[graphFilterType.GrossRefunds]: 'Refund Applications',
	[graphFilterType.ApprovedRefundsVolume]: 'Approved Refunds Volume',
	[graphFilterType.DeclinedRefundsVolume]: 'Declined Refunds Volume',
	[graphFilterType.GrossRefundsVolume]: 'Refund Applications Volume'
}

export const graphFilterColor: Record<graphFilterType, string> = {
	[graphFilterType.RefundProtectValue]: '#0084C4',
	[graphFilterType.YourRevenue]: '#23B77D',
	[graphFilterType.GrossBookingValue]: '#9D9DA4',
	[graphFilterType.RefundProtectVolume]: '#0084C4',
	[graphFilterType.GrossBookingVolume]: '#9D9DA4',
	[graphFilterType.RevenuePerDollar]: '#9D9DA4',
	[graphFilterType.AverageConversionRate]: '#FB4B4E',
	[graphFilterType.ProtectGroupProfit]: '#23B77D',
	[graphFilterType.ApprovedRefunds]: '#8CD17D',
	[graphFilterType.DeclinedRefunds]: '#F28E2B',
	[graphFilterType.GrossRefunds]: '#B07AA1',
	[graphFilterType.ApprovedRefundsVolume]: '#8CD17D',
	[graphFilterType.DeclinedRefundsVolume]: '#F28E2B',
	[graphFilterType.GrossRefundsVolume]: '#B07AA1'
}

export const graphValueTrends = [
	graphFilterType.YourRevenue,
	graphFilterType.RefundProtectValue,
	graphFilterType.GrossBookingValue,
	graphFilterType.AverageConversionRate
]

export const leadershipGraphValueTrends = [
	graphFilterType.YourRevenue,
	graphFilterType.RefundProtectValue,
	graphFilterType.GrossBookingValue,
	graphFilterType.AverageConversionRate,
	graphFilterType.GrossRefunds,
	graphFilterType.ApprovedRefunds,
	graphFilterType.DeclinedRefunds
]

export const allGraphValueTrends = [
	...graphValueTrends,
	...leadershipGraphValueTrends
]

export const graphVolumeTrends = [
	graphFilterType.RefundProtectVolume,
	graphFilterType.GrossBookingVolume
]

export const leadershipGraphVolumeTrends = [
	graphFilterType.RefundProtectVolume,
	graphFilterType.GrossBookingVolume,
	graphFilterType.GrossRefundsVolume,
	graphFilterType.ApprovedRefundsVolume,
	graphFilterType.DeclinedRefundsVolume
]

export const allGraphVolumeTrends = [
	...graphVolumeTrends,
	...leadershipGraphVolumeTrends
]

export const graphRevenue = [
	graphFilterType.RevenuePerDollar
]

export const validCustomerTrendsGraphTypes = [
	...graphValueTrends,
	...graphVolumeTrends,
	...graphRevenue
]

export enum settingsPageType {
	Account,
	RevenueShare
}

export enum batchAction {
	None,
	Cancel
}

export const batchActionName: Record<batchAction, string> = {
	[batchAction.None]: 'Actions',
	[batchAction.Cancel]: 'Cancel Selected'
}

export interface PulsePartnersRevenueTableFilter extends PulseFilter {
	page: number
	pageSize: number
	sortColumn: partnersRevenueTableSortColumn
	pricingModel: partnersRevenueTablePricingModel
}

export enum partnersRevenueTableSortColumn {
	MemberName,
	RefundProtectValue,
	ProfitValue,
	ProtectionValue,
	ApprovedRefundsValue,
	LossRatio
}

export const partnersRevenueTableSortColumnName: Record<partnersRevenueTableSortColumn, string> = {
	[partnersRevenueTableSortColumn.MemberName]: 'Name',
	[partnersRevenueTableSortColumn.RefundProtectValue]: 'RP Sales',
	[partnersRevenueTableSortColumn.ProfitValue]: 'Partner\'s Revenue',
	[partnersRevenueTableSortColumn.ProtectionValue]: 'PG Revenue',
	[partnersRevenueTableSortColumn.ApprovedRefundsValue]: 'Paid Refunds',
	[partnersRevenueTableSortColumn.LossRatio]: 'Loss Ratio',
}

export enum partnersRevenueTablePricingModel {
	All,
	Dynamic,
	Static
}

export const partnersRevenueTablePricingModelName: Record<partnersRevenueTablePricingModel, string> = {
	[partnersRevenueTablePricingModel.All]: 'All',
	[partnersRevenueTablePricingModel.Dynamic]: 'Dynamic',
	[partnersRevenueTablePricingModel.Static]: 'Static',
}

export interface PulseVerticalsRevenueTableFilter extends PulseFilter {
	sortColumn: verticalsRevenueTableSortColumn
}

export enum verticalsRevenueTableSortColumn {
	VerticalName,
	RefundProtectValue,
	ProfitValue,
	ProtectionValue,
	ApprovedRefundsValue,
	LossRatio
}

export const verticalsRevenueTableSortColumnName: Record<verticalsRevenueTableSortColumn, string> = {
	[verticalsRevenueTableSortColumn.VerticalName]: 'Name',
	[verticalsRevenueTableSortColumn.RefundProtectValue]: 'RP Sales',
	[verticalsRevenueTableSortColumn.ProfitValue]: 'Partner\'s Revenue',
	[verticalsRevenueTableSortColumn.ProtectionValue]: 'PG Revenue',
	[verticalsRevenueTableSortColumn.ApprovedRefundsValue]: 'Paid Refunds',
	[verticalsRevenueTableSortColumn.LossRatio]: 'Loss Ratio'
}

export enum verticalsRevenueTableBreakdownColumn {
	RefundProtectValue,
	ProfitValue,
	ProtectionValue,
	ApprovedRefundsValue,
	LossRatio
}

export const verticalsRevenueTableBreakdownColumnName: Record<verticalsRevenueTableBreakdownColumn, string> = {
	[verticalsRevenueTableBreakdownColumn.RefundProtectValue]: 'RP Sales',
	[verticalsRevenueTableBreakdownColumn.ProfitValue]: 'Partner\'s Revenue',
	[verticalsRevenueTableBreakdownColumn.ProtectionValue]: 'PG Revenue',
	[verticalsRevenueTableBreakdownColumn.ApprovedRefundsValue]: 'Paid Refunds',
	[verticalsRevenueTableBreakdownColumn.LossRatio]: 'Loss Ratio'
}

export const colorTheme = [
	'#4E79A7', '#A0CBE8', '#F28E2B', '#FFBE7D',
	'#59A14F', '#8CD17D', '#B6992D', '#F1CE63',
	'#499894', '#86BCB6', '#E15759', '#FF9D9A',
	'#79706E', '#BAB0AC', '#D37295', '#FABFD2',
	'#B07AA1', '#D4A6C8', '#9D7660', '#D7B5A6'
]

export const PulseTrendTag = {
	period: '[period]',
	value: '[value]'
}

export enum dashboardRestriction {
	Unknown,
	External,
	Internal,
	Leadership
}