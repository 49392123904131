import React from 'react'
import PrimaryValueCard from './Common/Cards/PrimaryValueCard'
import {useApiValue} from '../../../hooks/api'
import {dashboardRestrictionState, filterState, supportDetailState} from '../../../state/pulse'
import {usePulseApi} from '../../../hooks/api/pulse'
import useGetGraphFilterLabel from '../hooks/useGetGraphFilterLabel'
import {dashboardRestriction, graphFilterType} from '../../../types/pulse'
import {useRecoilValue} from 'recoil'
import DefaultButton from './Common/Buttons/DefaultButton'
import moment from 'moment'
import {dateToState} from '../../../state/pulse-filters'
import PrimaryVolumeCard from './Common/Cards/PrimaryVolumeCard'
import {Currency} from './Common/FormatElements'

const OverviewProfitValueSummary = () => {
	const {getProfitValueSummary, getProfitRateTrend} = usePulseApi()
	const profitValue = useApiValue(filterState, getProfitValueSummary, 0)
	const trendRate = useApiValue(filterState, getProfitRateTrend, 0)
	const getGraphFilterLabel = useGetGraphFilterLabel()
	const supportDetail = useRecoilValue(supportDetailState)
	const endDate = useRecoilValue(dateToState)
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)
	const commercialEmail = supportDetail.commercialEmail || process.env.REACT_APP_PULSE_SUPPORT_COMMERCIAL_EMAIL
	const activeFromDate = supportDetail.activeFromDate
		? moment(supportDetail.activeFromDate)
		: moment().startOf('day')

	const title = getGraphFilterLabel(graphFilterType.YourRevenue)
	const tooltipText = 'This refers to the share of revenue that you receive from the Refund Protect service. ' +
		'This metric represents the financial benefits accrued to your business based on the transactions where customers ' +
		'opted for Refund Protect.'

	const handleCommercialClick = () => {
		window.location.href = `mailto:${commercialEmail}?subject=Why Does the Data Look Incorrect?`
	}

	return (
		<>
			{dashboardRestrictions !== dashboardRestriction.Leadership && (
				<PrimaryValueCard
					value={profitValue.data}
					trendValue={trendRate.data}
					header={title}
					tooltipText={tooltipText}
					valueClassName="text-pulse-blue"
					isLoading={profitValue.isLoading || trendRate.isLoading}
					warning={profitValue.data <= 0 && activeFromDate.isSameOrBefore(endDate)}
					warningTitle="Wrong Data?"
					warningPopupTitle="Why Does the Data Look Incorrect?"
					warningPopupBody={(<>
						<p className="mb-2">
							It appears that the revenue data you're seeing is not accurate due to one of the following reasons:
							<ul className="list-disc p-4">
								<li>
									<span className="font-bold">Data Sharing Issues:</span> The information we have received may have
									discrepancies or may not have been provided in full. Please reach out to out Partnerships Team to ensure
									the data we receive is complete and accurate.
								</li>
							</ul>
						</p>
						<p>
							If you feel the data is incorrect or need assistance, feel free to contact our Partnerships Team, and we’ll
							gladly help resolve the issue.
						</p>
					</>)}
					warningPopupButtons={(
						<DefaultButton text="Contact Us" onClick={handleCommercialClick}/>
					)}
				/>
			)}
			{dashboardRestrictions === dashboardRestriction.Leadership && (
				<PrimaryVolumeCard
					value={<Currency value={profitValue.data}/>}
					trendValue={trendRate.data}
					header={<>{title}</>}
					icon="account_balance_wallet"
					tooltipTitle={title}
					tooltipText={tooltipText}
					valueClassName="text-black"
					isLoading={profitValue.isLoading || trendRate.isLoading}
				/>
			)}
		</>
	)
}

export default OverviewProfitValueSummary
