import React from 'react'
import {
	verticalsRevenueTableSortColumn,
	verticalsRevenueTableSortColumnName
} from '../../../../../types/pulse'
import {useRecoilState} from 'recoil'
import {verticalsRevenueTableSortColumnState} from '../../../../../state/pulse'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'

const VerticalsSortColumnDropdown = () => {
	const [sortColumn, setSortColumn] = useRecoilState(verticalsRevenueTableSortColumnState)

	const handleClick = (value: verticalsRevenueTableSortColumn) => {
		setSortColumn(value)
	}

	return (
		<Listbox value={sortColumn} onChange={(value) => handleClick(value)}>
			<ListboxButton>
				<div className="
					mb-2 pl-3 pr-2 py-[2px]
					bg-pulse-button-blue
					text-nowrap text-sm uppercase
					border border-pulse-icon-gray rounded-full
					cursor-pointer
					lg:mb-0"
				>
					<span className="leading-5 tracking-[1px]">{verticalsRevenueTableSortColumnName[sortColumn]}</span>
					<span className="
						align-middle ml-1
						leading-5
						material-symbols-outlined"
					>
						keyboard_arrow_down
					</span>
				</div>
			</ListboxButton>
			<ListboxOptions
				className="
					min-w-[var(--button-width)] ml-4 mt-[5px] py-2 w-11/12 z-50
					bg-white
					border border-pulse-popup-border-gray rounded-lg
					lg:w-auto lg:ml-0"
				anchor="bottom start"
			>
				<DropdownOption sortColumn={verticalsRevenueTableSortColumn.VerticalName}/>
				<DropdownOption sortColumn={verticalsRevenueTableSortColumn.ProtectionValue}/>
				<DropdownOption sortColumn={verticalsRevenueTableSortColumn.RefundProtectValue}/>
				<DropdownOption sortColumn={verticalsRevenueTableSortColumn.ProfitValue}/>
				<DropdownOption sortColumn={verticalsRevenueTableSortColumn.ApprovedRefundsValue}/>
				<DropdownOption sortColumn={verticalsRevenueTableSortColumn.LossRatio}/>
			</ListboxOptions>
		</Listbox>
	)
}

const DropdownOption = (props: {sortColumn: verticalsRevenueTableSortColumn}) => {
	return (
		<ListboxOption
			value={props.sortColumn}
			className="
				px-4 py-2
				text-sm text-black tracking-[1px] uppercase
				cursor-pointer
				data-[focus]:bg-pulse-button-blue"
		>
			{verticalsRevenueTableSortColumnName[props.sortColumn]}
		</ListboxOption>
	)
}

export default VerticalsSortColumnDropdown
