import React, {useState} from 'react'
import classNames from 'classnames'
import MonthsFilter from './Common/Filter/MonthsFilter'
import CalendarDatesFilter from './Common/Filter/CalendarDatesFilter'
import YearFilter from './Common/Filter/YearFilter'
import YearToDateFilter from './Common/Filter/YearToDateFilter'
import CurrencyFilter from './Common/Filter/CurrencyFilter'
import RegionFilter from './Common/Filter/RegionFilter'
import MemberFilter from './Common/Filter/MemberFilter'
import ExportFilter from './Common/Filter/ExportFilter'
import {useRecoilValue} from 'recoil'
import {
	enableFiltersState,
	exportDataState,
	userMemberHasCFAR
} from '../../../state/pulse'
import AllTimeFilter from './Common/Filter/AllTimeFilter'
import ProductFilter from './Common/Filter/ProductFilter'

const DashboardFilter = () => {
	const [showMobileFilter, setShowMobileFilter] = useState(false)
	const handleCloseFilter = () => setShowMobileFilter(false)
	const handleOpenFilter = () => setShowMobileFilter(true)
	const exportData = useRecoilValue(exportDataState)
	const userHasCFAR = useRecoilValue(userMemberHasCFAR)
	const enableFilters = useRecoilValue(enableFiltersState)

	return (
		<section
			className={classNames('max-w-6xl pt-10 px-4 relative w-full',
				'bg-pulse-gray',
				'lg:bg-white lg:h-auto lg:mx-auto lg:pt-0 lg:px-10', {
					'h-6 overflow-hidden': !showMobileFilter,
					'h-auto': showMobileFilter,
					'pointer-events-none opacity-40': !enableFilters
				})}
		>
			<div className="
				justify-between py-2 static
				lg:pb-4 lg:pt-0"
			>
				<div className="w-full lg:flex">
					<div className="
						flex flex-1 flex-wrap mb-2
						lg:gap-y-2 lg:mb-0"
					>
						<MonthsFilter/>
						<YearToDateFilter/>
						<YearFilter/>
						<CalendarDatesFilter/>
						<AllTimeFilter/>
					</div>
					<div className="flex flex-none flex-wrap">
						<CurrencyFilter/>
						<RegionFilter/>
						{exportData != null && (<ExportFilter exportData={exportData}/>)}
					</div>
				</div>
				<div className="w-full lg:flex">
					<div className="
						flex-1 flex-wrap mb-2 mt-4
						lg:flex lg:mb-0"
					>
						<MemberFilter/>
						{userHasCFAR && (<ProductFilter/>)}
					</div>
				</div>
			</div>
			<div className="
				absolute right-4 top-[6px]
				cursor-pointer
				lg:hidden"
			>
				{showMobileFilter && (
					<span
						className="
							align-middle
							text-lg
							material-symbols-outlined"
						onClick={handleCloseFilter}
					>
						close
					</span>
				)}
				{!showMobileFilter && (
					<span
						className="
							align-middle
							text-lg
							material-symbols-outlined"
						onClick={handleOpenFilter}
					>
						tune
					</span>
				)}
			</div>
		</section>
	)
}

export default DashboardFilter
