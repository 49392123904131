import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import {dashboardRestriction, graphFilterName, graphFilterType} from '../../../types/pulse'
import {formatCurrency} from '../../../utils/currency'
import {dashboardRestrictionState} from '../../../state/pulse'

export default function useGetGraphFilterLabel() {
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)
	const formattedValue = formatCurrency(coreCurrency, 0)
	const currencySymbol = formattedValue
		.replace(/\d/g, '')
		.replace('.', '')
		.trim()

	return (filter: graphFilterType) => {
		if (filter === graphFilterType.RevenuePerDollar) {
			return `Gross Revenue per ${currencySymbol}`
		}

		if (filter === graphFilterType.YourRevenue) {
			return dashboardRestrictions === dashboardRestriction.External
				? graphFilterName[graphFilterType.YourRevenue]
				: 'Partner\'s Revenue'
		}

		return graphFilterName[filter]
	}
}
