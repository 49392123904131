import React from 'react'
import PrimaryVolumeCard from './Common/Cards/PrimaryVolumeCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {PulseTrendTag} from '../../../types/pulse'
import {Percentage} from './Common/FormatElements'

const OverviewLossRatioValueSummary = () => {
	const {getLossRatioRate, getLossRatioTrend} = usePulseApi()
	const value = useApiValue(filterState, getLossRatioRate, 0)
	const trendRate = useApiValue(filterState, getLossRatioTrend, 0)

	return (
		<PrimaryVolumeCard
			icon="account_balance_wallet"
			isLoading={value.isLoading || trendRate.isLoading}
			header={<>Loss Ratio</>}
			tooltipText="The percentage of revenue used to pay approved refunds."
			tooltipTitle=""
			trendText={`It used to be ${PulseTrendTag.value}% last ${PulseTrendTag.period}`}
			trendValue={trendRate.data}
			value={<Percentage value={value.data}/>}
			valueClassName="text-pulse-orange"
		/>
	)
}

export default OverviewLossRatioValueSummary
