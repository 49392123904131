import {usePulseApi} from '../../../hooks/api/pulse'
import {partnersRevenueTableFilterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import moment from 'moment'
import {ExportData} from '../../../types/pulse-data'
import {useExportData} from './useExportData'
import {formatCurrency} from '../../../utils/currency'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'

export default function useExportPartnersRevenueData(): ExportData {
	const {
		getPartnersRevenueTableData
	} = usePulseApi()
	const filter = useRecoilValue(partnersRevenueTableFilterState)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)

	const getData = async (): Promise<any> => {
		const result = await getPartnersRevenueTableData({
			...filter,
			pageSize: 0
		})
		const data = []

		if (!result.members) {
			return []
		}

		for (let i = 0; i < result.members.length; i++) {
			const row = result.members[i]

			data.push({
				'Partner': row.memberName,
				'Pricing Model': row.pricingModel,
				'PG Revenue': formatCurrency(coreCurrency, row.protectionValue || 0, 0, 0),
				'RP Sales': formatCurrency(coreCurrency, row.refundProtectValue || 0, 0, 0),
				'Partner\'s Revenue': formatCurrency(coreCurrency, row.profitValue || 0, 0, 0),
				'Paid Refunds': formatCurrency(coreCurrency, row.approvedRefundsValue || 0, 0, 0),
				'Loss Ratio': row.lossRatio + '%'
			})
		}

		return data
	}
	const getFileName = () => `Partners-${moment().toISOString()}`
	const getHeaders = () => [
		'Partner',
		'Pricing Model',
		'PG Revenue',
		'RP Sales',
		'Partner\'s Revenue',
		'Paid Refunds',
		'Loss Ratio'
	]

	return useExportData({
		getData: getData,
		getFileName: getFileName,
		getHeaders: getHeaders
	})
}
