import {ChartDataset, ChartOptions, TooltipItem} from 'chart.js'
import {PulseTableDataModel} from '../../../types/pulse-data'
import {graphFilterColor, graphFilterType} from '../../../types/pulse'
import {formatCompactCurrency, formatCurrency} from '../../../utils/currency'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import useGetGraphFilterLabel from './useGetGraphFilterLabel'

export default function useGetOverviewLeadershipRefundChartData(recoilValue: { data: PulseTableDataModel, isLoading: boolean }) {
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const getGraphFilterLabel = useGetGraphFilterLabel()

	const getOptions = () => {
		const options: ChartOptions<'line'> = {
			scales: {
				x: {
					grid: {
						display: false
					},
				},
				y0: {
					display: true,
					position: 'left',
					ticks: {
						callback: function (value) {
							return formatCompactCurrency(coreCurrency, parseFloat(value.toString()))
						}
					},
					type: 'linear'
				}
			},
			plugins: {
				legend: {
					labels: {
						usePointStyle: true,
						padding: 30
					}
				},
				tooltip: {
					enabled: true,
					mode: 'index',
					bodySpacing: 8,
					boxPadding: 4,
					padding: 16,
					titleMarginBottom: 8,
					callbacks: {
						label: (tooltip: TooltipItem<'line'>) => {
							return `${tooltip.dataset.label}: ${formatCurrency(coreCurrency, tooltip.raw as any)}`
						}
					},
					itemSort: (a, b) => {
						return (b.raw as number) - (a.raw as number)
					}
				}
			}
		}

		return options
	}

	const getLabels = () => recoilValue.data.labels

	const buildDataset = (filterType: graphFilterType,
	                      chartData: Record<number, number[]>,
	                      color: string = '',
	                      label: string = '',
	                      hidden: boolean = false): ChartDataset<'line'> => {
		return {
			label: label ? label : getGraphFilterLabel(filterType),
			data: chartData[filterType],
			fill: false,
			borderColor: color ? color : graphFilterColor[filterType],
			backgroundColor: color ? color : graphFilterColor[filterType],
			borderWidth: 2,
			pointRadius: 2,
			tension: 0.3,
			hidden: hidden
		}
	}

	const getDataset = (): ChartDataset<'line'>[] => {
		if (!recoilValue.data.chartData) {
			return []
		}

		return [
			{
				...buildDataset(graphFilterType.ProtectGroupProfit, recoilValue.data.chartData),
				yAxisID: `y0`,
				label: 'PG Gross Margin'
			},
			{
				...buildDataset(graphFilterType.ApprovedRefunds, recoilValue.data.chartData),
				yAxisID: `y0`,
				label: 'Approved Refunds'
			},
			{
				...buildDataset(graphFilterType.RefundProtectValue, recoilValue.data.chartData),
				yAxisID: `y0`,
				label: 'RP Sales',
				hidden: true
			}
		]
	}

	return {
		getLabels,
		getDataset,
		getOptions
	}
}
