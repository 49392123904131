import React from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import {useRecoilValue} from 'recoil'
import OverviewStandardView from './components/OverviewStandardView'
import OverviewLeadershipView from './components/OverviewLeadershipView'
import {dashboardRestrictionState} from '../../state/pulse'
import {dashboardRestriction} from '../../types/pulse'

const PulseOverviewView = () => {
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)

	return (
		<DashboardPageLayout>
			<>
				{(dashboardRestrictions === dashboardRestriction.Internal ||
					dashboardRestrictions === dashboardRestriction.External) && (
					<OverviewStandardView/>
				)}
				{dashboardRestrictions === dashboardRestriction.Leadership && (
					<OverviewLeadershipView/>
				)}
			</>
		</DashboardPageLayout>
	)
}

export default PulseOverviewView
