import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../../state/pulse-filters'
import {formatCurrency} from '../../../../utils/currency'
import React from 'react'
import {formatNumberMember} from '../../../../utils/number'

export const Currency = (props: { value: number | undefined, max?: number }) => {
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)

	return (<>{formatCurrency(coreCurrency, props.value || 0, 0, props.max || 0)}</>)
}

export const Numbers = (props: { value: number | undefined }) => {
	return (<>{formatNumberMember(props.value || 0)}</>)
}

export const Percentage = (props: { value: number | undefined }) => {
	return (<>{props.value}%</>)
}

export const Obfuscate = (props: { value: string | undefined }) => {
	return (<>{props.value ? props.value.substring(0, 1).toUpperCase() : ''}**** *****</>)
}