import React, {useEffect} from 'react'
import {
	dashboardRestrictionState,
	exportDataState,
	memberTransactionsOutOfRangeState,
	titleSuffixState
} from '../../../state/pulse'
import useExportOverviewData from '../hooks/useExportOverviewData'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import H2 from './Common/Headers/H2'
import CardTab from './Common/Cards/CardTab'
import OverviewProfitValueSummary from './OverviewProfitValueSummary'
import OverviewProtectionValueSummary from './OverviewProtectionValueSummary'
import OverviewRefundProtectValueSummary from './OverviewRefundProtectValueSummary'
import OverviewRefundProtectVolumeSummary from './OverviewRefundProtectVolumeSummary'
import OverviewBookingValueSummary from './OverviewBookingValueSummary'
import OverviewBookingVolumeSummary from './OverviewBookingVolumeSummary'
import DataAvailabilityWarningOverlay from './DataAvailabilityWarningOverlay'
import OverviewComparisonChart from './OverviewComparisonChart'
import OverviewConversionRateSummary from './OverviewConversionRateSummary'
import OverviewPriceToCustomerSummary from './OverviewPriceToCustomerSummary'
import OverviewRevenuePerTransactionSummary from './OverviewRevenuePerTransactionSummary'
import OverviewRevenuePerCurrencySummary from './OverviewRevenuePerCurrencySummary'
import OverviewGraphChart from './OverviewGraphChart'
import RefundTimeSummary from './RefundTimeSummary'
import RefundAutoApprovalSummary from './RefundAutoApprovalSummary'
import RefundEmailVolumeSummary from './RefundEmailVolumeSummary'
import RefundTrustPilotReviews from './RefundTrustPilotReviews'
import DashboardSupport from './DashboardSupport'
import RefundTrustPilotScoreSummary from './RefundTrustPilotScoreSummary'
import {ViewGrid} from './Common/LayoutElements'
import {dashboardRestriction} from '../../../types/pulse'

const OverviewStandardView = () => {
	const exportData = useExportOverviewData()
	const titleSuffix = useRecoilValue(titleSuffixState)
	const memberTransactionsOutOfRange = useRecoilValue(memberTransactionsOutOfRangeState)
	const setExportDataState = useSetRecoilState(exportDataState)
	const dashboardRestrictions = useRecoilValue(dashboardRestrictionState)

	useEffect(() => {
		setExportDataState(exportData)
	}, [exportData])

	return (
		<>
			<section className="
				max-w-6xl mb-1 px-4 relative w-full
				lg:mx-auto lg:px-10"
			>
				<H2>Overview - {titleSuffix}</H2>
				{!memberTransactionsOutOfRange && (
					<div className="
						grid grid-cols-1 gap-4
						2md:grid-cols-2 lg:grid-cols-3"
					>
						<CardTab
							className="2md:col-span-2 lg:col-span-1"
							bodyClassName="bg-pulse-highlight-blue"
							header="Revenue Share"
						>
							<OverviewProfitValueSummary/>
							{dashboardRestrictions === dashboardRestriction.Internal && (
								<OverviewProtectionValueSummary/>
							)}
						</CardTab>
						<CardTab header="Refund Protect Sales">
							<OverviewRefundProtectValueSummary/>
							<OverviewRefundProtectVolumeSummary/>
						</CardTab>
						<CardTab header="Total Sales">
							<OverviewBookingValueSummary/>
							<OverviewBookingVolumeSummary/>
						</CardTab>
					</div>
				)}
				{memberTransactionsOutOfRange && (<DataAvailabilityWarningOverlay/>)}
			</section>
			{!memberTransactionsOutOfRange && (
				<>
					<section className="
						hidden max-w-6xl mt-4 px-4 w-full
						md:block lg:mx-auto lg:px-10"
					>
						<OverviewComparisonChart/>
					</section>
					<section className="
						max-w-6xl mt-2 px-4 w-full
						lg:mx-auto lg:px-10"
					>
						<ViewGrid>
							<OverviewConversionRateSummary/>
							<OverviewPriceToCustomerSummary/>
							<OverviewRevenuePerTransactionSummary/>
							<OverviewRevenuePerCurrencySummary/>
						</ViewGrid>
					</section>
					<section className="
						max-w-6xl mt-4 pb-8 px-4 w-full
						lg:mx-auto lg:px-10"
					>
						<OverviewGraphChart/>
					</section>
					<section className="bg-pulse-gray">
						<div className="
							max-w-6xl px-4 py-8 w-full
							bg-pulse-gray
							lg:mx-auto lg:px-10"
						>
							<h2 className="
								mb-2
								font-semibold text-2xl"
							>
								Customer Experience
							</h2>
							<ViewGrid>
								<RefundTimeSummary/>
								<RefundAutoApprovalSummary/>
								<RefundEmailVolumeSummary/>
								<RefundTrustPilotScoreSummary/>
							</ViewGrid>
							<div className="mt-12">
								<RefundTrustPilotReviews/>
							</div>
						</div>
					</section>
				</>
			)}
			<section className="
				max-w-6xl px-4 py-8 w-full
				lg:mx-auto lg:px-10"
			>
				<DashboardSupport/>
			</section>
		</>
	)
}

export default OverviewStandardView
